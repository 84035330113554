
.accordion-item {
    border-bottom: 1px solid $color-primary;
}

.accordion-item__header {
    cursor: pointer;
    position: relative;
    padding-right: 56px; /* um überlagerung des Icons zu verhindern */
    &:after {
        content: '';
        display: flex;
        position: absolute;
        top: calc(50% - 8px);
        right: 0;
        width: 16px;
        height: 16px;
        background-size: 14px 14px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: inline-svg('angle-down', $color-black);
        transition: transform 0.3s;
    }
}

.accordion__item.uk-open .accordion-item__header:after {
    transform: rotate(180deg);
}




