@import "@sx-core/src/scss/element/article.scss";

.bg--coloured {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;

    & > *:first-child {
        margin-top: initial;
    }

    & > *:last-child {
        padding-bottom: initial;
        margin-bottom: initial;
    }

    &.bg--green {
        background-color: $color-greenish;
    }

    &.bg--purple {
        background-color: $color-primary-1100;
        padding-bottom: initial;

        h2 {
            margin-top: 0;
            @include getFigmaStyle('Desktop/H3/Medium/Default');
        }

        h1, h2, h3, h4, h5, h6, p, div {
            color: $color-white;
        }
    }
}

.headline {
    margin-bottom: pxToRem(48);
}

.article-inner .content {
    overflow-wrap: break-word;
    hyphens: auto;
}

@include media-breakpoint-down(md) {
    .headline {
        margin-bottom: pxToRem(36);
    }

    .bg--coloured {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }
}

.article-inner .content--content2.content {
    padding-right: pxToRem(72);

    p {
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/LG/Medium/Default');
    }
}

@include media-breakpoint-down(md) {
    .content--content2.content {
        padding-right: 0;
        margin-bottom: pxToRem(12);
    }
}

.copyright--list > li {
    width: 100% !important;
}