.contact {
    h3, p {
        color: $color-white;
    }

    &-inner {
        padding: pxToRem(96) 0;
        background-color: $color-primary-1100;

        & > .grid {
            row-gap: pxToRem(96);
        }
    }

    &-item__header h3 {
        padding-right: pxToRem(48);
        hyphens: auto;
    }

    .contact__comment {
        padding-top: pxToRem(48);
        padding-bottom: pxToRem(64);
        background-color: $color-greenish;

        p {
            color: $color-primary-800;
            @include getFigmaStyle('Desktop/MD/Medium/Default');
        }
    }

    &__cta, .downloads {
        margin-top: pxToRem(48);
    }

    .downloads {
        a {
            color: $color-white;
        }
    }
}

.contact .timetable {
    //margin-top: pxToRem(64);
}

.contact-item {
    color: $color-white;
}

@include media-breakpoint-down(md) {
    .contact {
        &-item__header {
            h3 {
                padding-right: 0;
            }
        }
        &-inner > .grid {
            row-gap: pxToRem(72);
        }

        &-item__content .content a:not(.contact__cta) {
            color: $color-secondary-800;
        }
    }
}