@import "@sx-core/src/scss/element/teaser.scss";

.teaser {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    background: $color-greenish;
}

.teaser-item {
    position: relative;
    display: block;

    &__image {
        position: relative;
        transition: all .3s ease;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color-image-default;
            transition: all .3s ease;
        }
    }

    &__body {
        position: absolute;
        bottom: 36px;
        display: flex;
        justify-content: center;
        width: 100%;
        transition: all .3s ease;
    }

    &__headline.headline {
        padding: pxToRem(11) pxToRem(16) pxToRem(16);
        margin-bottom: initial;
        color: $color-primary-1400;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
        background-color: $color-secondary-800;
        border-radius: $border-radius;
        transition: all .3s ease;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {

            .teaser-item__image {
                box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.25);

                &::after {
                    background-color: $color-image-hover;
                }
            }

            .teaser-item__body {
                bottom: 48px;
            }

            .teaser-item__headline.headline {
                color: $color-white;
                background-color: $color-primary-800;
            }
        }
    }
}

/****/

/*
@include media-breakpoint-up(lg) {
    .teaser-grid {
        display: flex;
        flex-wrap: initial;
        height: 30vw;
    }

    .teaser__item {
        flex: 1;
        height: 30vw;
        transition: all .8s ease;

        &.col--3-12\@xl:hover {
            flex: 1.8;
        }

        &.col--4-12\@xl:hover {
            flex: 1.2;
        }
    }

    .teaser-item {
        height: 100%;
    }

    .teaser-item__image {
        overflow: hidden;
        height: 100%;

        .image {
            position: relative;
            display: block;
            height: 100%;

            img {
                display: block;
                height: 100%;
            }
        }
    }
}
 */

@include media-breakpoint-up(lg) {
    .teaser-grid {
        display: flex;
    }

    .teaser__item {
        position: relative;
        flex: 1;
        padding-bottom: 70vh;
        overflow: hidden;
        transition: all 1s ease;

        &:hover {
            flex: 1.8;
        }

        &.col--4-12\@xl:hover {
            flex: 1.3;
        }
    }

    .teaser-item {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: calc(70vh * 1);
    }
}

/****/

@include media-breakpoint-down(xl) {
    .teaser {
        &-grid {
            row-gap: pxToRem(12);
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
        padding-left: 0;
        padding-right: 0;

        .grid.grid--gutter {
            margin-left: 0;
            margin-right: 0;
        }

        .grid.grid--gutter > [class^=col], .grid.grid--gutter > [class*=col] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

/*** || Textteaser ***/

.teaser-text {
    &-grid {
        row-gap: pxToRem(36);
    }

    &__item {
        height: auto;
        aspect-ratio: 2 / 1;
    }

    h3 {
        color: $color-primary-1400;
        margin-bottom: pxToRem(12);
        @include getFigmaStyle('Desktop/LG/Bold/Default');
    }

    .teasertext {
        color: $color-primary-1400;
    }
}

.teaser-text-item {
    display: flex;
    height: 100%;
    padding: pxToRem(16);
    @include getFigmaStyle('Shadow/MD');
    transition: all .3s ease;

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
    }

    &__readmore {
        margin-top: pxToRem(28);
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 1px 30px 0 rgba($color-black, 0.25);

            .teaser-text-item__readmore__inner {
                color: $color-white;
                background-color: $color-primary-800;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-text {
        &__item {
            aspect-ratio: 5 / 4;
        }
    }
}