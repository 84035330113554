@import "@sx-core/src/scss/element/footer.scss";

.footer {
    height: auto;

    & > .container {
        height: auto;
    }

    &-top {
        padding: pxToRem(96) 0 pxToRem(48);
        color: $color-white;
        background-color: $color-primary-1100;
    }

    &-grid {
        row-gap: pxToRem(96);
    }

    &__cta {
        margin-left: auto;
    }

    &-top__upper {
        align-items: flex-start;
    }

    &__navigation {
        margin-left: auto;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            column-gap: pxToRem(36);
            width: 100%;
            margin: initial;
        }

        ul > li {
            padding: initial;
        }

        ul > li a {
            color: $color-white;
            font-size: pxToRem(17);
            @include getFigmaStyle('Desktop/MD/Regular/Default');
        }
    }

    &-bottom {
        padding: pxToRem(48) 0 pxToRem(64);

        &__right {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__cookies {
        position: relative;
        display: flex;
        align-items: center;
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        text-decoration-color: transparent;
        transition: all .3s ease;

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: pxToRem(16);
            background-image: inline-svg('cookie', $color-white, transparent);
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus, &:active {
                text-decoration-color: $color-white;
            }
        }

        &.footer__cookies--mobile {
            color: $color-white;
            text-decoration: none;

            &::before {
                background-image: inline-svg('cookie', $color-white, transparent);
                border-color: $color-white;
            }
        }
    }
}

.contact {
    display: flex;
    flex-direction: column;

    &__title {
        @include getFigmaStyle('Desktop/MD/Bold/Default');
        margin-bottom: pxToRem(12);
    }

    &__address {
        //margin-bottom: pxToRem(12);
    }

    .email {
        @include getFigmaStyle('Desktop/MD/Regular/Underline');
    }

    &__phone, &__fax, &__email {
        span {
            display: inline-block;
            min-width: pxToRem(100);
            @include getFigmaStyle('Desktop/MD/Medium/Default');
        }
    }

    &__title {
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }

    &__subline, &__persons {
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    a {
        color: $color-primary-1300;
    }

    &.footer__contact {
        p {
            color: $color-grey-1300;
        }

        .contact__address {
            margin-bottom: pxToRem(12);
            @include getFigmaStyle('Desktop/MD/Regular/Default');
        }
    }
}


@include media-breakpoint-down(md) {
    .footer {
        &-grid {
            row-gap: pxToRem(48);
        }

        .footer-top {
            padding: pxToRem(48) 0;

            &__upper {
                row-gap: pxToRem(24);
            }
        }

        &__cta {
            width: 100%;
            margin-top: pxToRem(24);
            margin-left: initial;
        }

        &__cookies {
            order: 1;
        }

        &-top__lower {
            row-gap: pxToRem(48);
        }
    }

    .footer__navigation {
        order: 0;
        margin-left: initial;

        ul {
            justify-content: flex-start;
            gap: pxToRem(16);
            padding-bottom: pxToRem(28);
            border-bottom: 1px solid $color-secondary-800;
        }
    }

    .footer-bottom {
        & > .grid {
            row-gap: pxToRem(48);
        }

        &__right {
            justify-content: flex-start;
        }
    }

    .footer__contact {
        row-gap: pxToRem(12);

        a {
            display: flex;
            flex-direction: column;
        }
    }
}