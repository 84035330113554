.contentelement {
    &__item {
        display: flex;

        &:not(:first-child) {
            margin-top: $modulspacing-desktop-m;
        }

        &:nth-child(odd) {
            .contentelement-item__image {
                order: 2;

                .image::before {
                    right: initial;
                    left: -26px;
                }
            }

            .contentelement-item__body {
                padding-left: initial;
                padding-right: pxToRem(96);
            }
        }
    }
}

.contentelement-item {
    display: flex;
    flex-wrap: wrap;

    &__image {
        position: relative;

        .image::before {
            content: '';
            position: absolute;
            right: -26px;
            bottom: -26px;
            z-index: -1;
            display: block;
            width: calc(100% - 112px);
            height: calc(100% - 64px);
            background-color: $color-greenish;
        }
    }

    &__body {
        padding-top: pxToRem(96);
        padding-left: pxToRem(96);
    }
}

@include media-breakpoint-down(md) {
    .contentelement {
        &__item {
            .contentelement-item__body {
                padding-left: initial;
                padding-right: 0 !important;
            }

            &:not(:first-child) {
                margin-top: $modulspacing-mobile-m;
            }
        }
    }

    .contentelement-item {
        &__image {
            order: 2;
            margin-top: pxToRem(28);

            .image::before {
                right: initial;
                left: -26px;
            }
        }

        &__body {
            padding-top: 0;
            padding-left: 0;
        }
    }
}