.team {
    & > .grid {
        row-gap: pxToRem(24);
    }
}

.team-item {
    padding-left: pxToRem(26);
    padding-bottom: pxToRem(26);

    &__image {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: -26px;
            bottom: -57px;
            z-index: -1;
            display: block;
            width: 85%;
            height: calc(100% - 57px);
            background-color: $color-greenish;
        }
    }

    &.has-subline {
        .team-item__image::before {
            height: calc(100% - 28px);
            bottom: -86px;
        }
    }

    &__headline.headline {
        margin-top: pxToRem(12);
        margin-bottom: initial;
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }

    &__headline--subline.headline--subline.headline {
        margin-top: pxToRem(4);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/XS/Regular/Default');
    }
}

@include media-breakpoint-down(md) {
    .team-item {
        &__headline.headline {
            margin-top: pxToRem(15);
        }
    }
}