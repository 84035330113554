@import "@sx-core/src/scss/base/image.base.scss";
@import "@sx-core/src/scss/element/image.scss";

.image__description {
    margin-top: pxToRem(16);
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/SM/Medium/Default');
}

.image.ratio--xl picture {
    position: relative;
}