%MobileXsLightDefault {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileXsLightUnderline {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXsLightUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXsLightUnderlineUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXsLightLineThrough {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXsLightLineThroughUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXsRegularDefault {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileXsRegularUnderline {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXsRegularUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXsRegularUnderlineUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXsRegularLineThrough {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXsRegularLineThroughUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXsMediumDefault {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileXsMediumUnderline {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXsMediumUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXsMediumUnderlineUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXsMediumLineThrough {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXsMediumLineThroughUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXsBoldDefault {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileXsBoldUnderline {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXsBoldUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXsBoldUnderlineUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXsBoldLineThrough {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXsBoldLineThroughUppercase {
    font-size: 0.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileSmLightDefault {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileSmLightUnderline {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileSmLightUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileSmLightUnderlineUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileSmLightLineThrough {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileSmLightLineThroughUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileSmRegularDefault {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileSmRegularUnderline {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileSmRegularUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileSmRegularUnderlineUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileSmRegularLineThrough {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileSmRegularLineThroughUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileSmMediumDefault {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileSmMediumUnderline {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileSmMediumUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileSmMediumUnderlineUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileSmMediumLineThrough {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileSmMediumLineThroughUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileSmBoldDefault {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileSmBoldUnderline {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileSmBoldUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileSmBoldUnderlineUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileSmBoldLineThrough {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileSmBoldLineThroughUppercase {
    font-size: 0.875rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileMdLightDefault {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileMdLightUnderline {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileMdLightUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileMdLightUnderlineUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileMdLightLineThrough {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileMdLightLineThroughUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileMdRegularDefault {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileMdRegularUnderline {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileMdRegularUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileMdRegularUnderlineUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileMdRegularLineThrough {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileMdRegularLineThroughUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileMdMediumDefault {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileMdMediumUnderline {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileMdMediumUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileMdMediumUnderlineUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileMdMediumLineThrough {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileMdMediumLineThroughUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileMdBoldDefault {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}

%MobileMdBoldUnderline {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: underline;
    text-transform: none;
}

%MobileMdBoldUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileMdBoldUnderlineUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileMdBoldLineThrough {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileMdBoldLineThroughUppercase {
    font-size: 1rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileLgLightDefault {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileLgLightUnderline {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileLgLightUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileLgLightUnderlineUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileLgLightLineThrough {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileLgLightLineThroughUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileLgRegularDefault {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileLgRegularUnderline {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileLgRegularUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileLgRegularUnderlineUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileLgRegularLineThrough {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileLgRegularLineThroughUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileLgMediumDefault {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileLgMediumUnderline {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileLgMediumUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileLgMediumUnderlineUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileLgMediumLineThrough {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileLgMediumLineThroughUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileLgBoldDefault {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileLgBoldUnderline {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileLgBoldUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileLgBoldUnderlineUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileLgBoldLineThrough {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileLgBoldLineThroughUppercase {
    font-size: 1.188rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXlLightDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXlLightUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXlLightUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXlLightUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXlLightLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXlLightLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXlRegularDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXlRegularUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXlRegularUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXlRegularUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXlRegularLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXlRegularLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXlMediumDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXlMediumUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXlMediumUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXlMediumUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXlMediumLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXlMediumLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXlBoldDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXlBoldUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXlBoldUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXlBoldUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXlBoldLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXlBoldLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXxlLightDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXxlLightUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXxlLightUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXxlLightUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXxlLightLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXxlLightLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXxlRegularDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXxlRegularUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXxlRegularUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXxlRegularUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXxlRegularLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXxlRegularLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXxlMediumDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXxlMediumUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXxlMediumUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXxlMediumUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXxlMediumLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXxlMediumLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileXxlBoldDefault {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
}

%MobileXxlBoldUnderline {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: underline;
    text-transform: none;
}

%MobileXxlBoldUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileXxlBoldUnderlineUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileXxlBoldLineThrough {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileXxlBoldLineThroughUppercase {
    font-size: 2.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH6LightDefault {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileH6LightUnderline {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH6LightUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH6LightUnderlineUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH6LightLineThrough {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH6LightLineThroughUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH6RegularDefault {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileH6RegularUnderline {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH6RegularUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH6RegularUnderlineUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH6RegularLineThrough {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH6RegularLineThroughUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH6MediumDefault {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileH6MediumUnderline {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH6MediumUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH6MediumUnderlineUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH6MediumLineThrough {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH6MediumLineThroughUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH6BoldDefault {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: none;
    text-transform: none;
}

%MobileH6BoldUnderline {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH6BoldUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH6BoldUnderlineUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH6BoldLineThrough {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH6BoldLineThroughUppercase {
    font-size: 1.125rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH5LightDefault {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    text-decoration: none;
    text-transform: none;
}

%MobileH5LightUnderline {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH5LightUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH5LightUnderlineUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH5LightLineThrough {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH5LightLineThroughUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH5RegularDefault {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    text-decoration: none;
    text-transform: none;
}

%MobileH5RegularUnderline {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH5RegularUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH5RegularUnderlineUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH5RegularLineThrough {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH5RegularLineThroughUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH5MediumDefault {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: none;
    text-transform: none;
}

%MobileH5MediumUnderline {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH5MediumUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH5MediumUnderlineUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH5MediumLineThrough {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH5MediumLineThroughUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH5BoldDefault {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: none;
    text-transform: none;
}

%MobileH5BoldUnderline {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH5BoldUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH5BoldUnderlineUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH5BoldLineThrough {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH5BoldLineThroughUppercase {
    font-size: 1.25rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 128%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH4LightDefault {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    text-decoration: none;
    text-transform: none;
}

%MobileH4LightUnderline {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH4LightUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH4LightUnderlineUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH4LightLineThrough {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH4LightLineThroughUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH4RegularDefault {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    text-decoration: none;
    text-transform: none;
}

%MobileH4RegularUnderline {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH4RegularUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH4RegularUnderlineUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH4RegularLineThrough {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH4RegularLineThroughUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH4MediumDefault {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: none;
    text-transform: none;
}

%MobileH4MediumUnderline {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH4MediumUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH4MediumUnderlineUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH4MediumLineThrough {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH4MediumLineThroughUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH4BoldDefault {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: none;
    text-transform: none;
}

%MobileH4BoldUnderline {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH4BoldUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH4BoldUnderlineUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH4BoldLineThrough {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH4BoldLineThroughUppercase {
    font-size: 1.375rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 126%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH3LightDefault {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    text-decoration: none;
    text-transform: none;
}

%MobileH3LightUnderline {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH3LightUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH3LightUnderlineUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH3LightLineThrough {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH3LightLineThroughUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH3RegularDefault {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    text-decoration: none;
    text-transform: none;
}

%MobileH3RegularUnderline {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH3RegularUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH3RegularUnderlineUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH3RegularLineThrough {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH3RegularLineThroughUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH3MediumDefault {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: none;
    text-transform: none;
}

%MobileH3MediumUnderline {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH3MediumUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH3MediumUnderlineUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH3MediumLineThrough {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH3MediumLineThroughUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH3BoldDefault {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: none;
    text-transform: none;
}

%MobileH3BoldUnderline {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH3BoldUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH3BoldUnderlineUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH3BoldLineThrough {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH3BoldLineThroughUppercase {
    font-size: 1.75rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 124%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH2LightDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH2LightUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH2LightUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH2LightUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH2LightLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH2LightLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH2RegularDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH2RegularUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH2RegularUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH2RegularUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH2RegularLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH2RegularLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH2MediumDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH2MediumUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH2MediumUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH2MediumUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH2MediumLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH2MediumLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH2BoldDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH2BoldUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH2BoldUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH2BoldUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH2BoldLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH2BoldLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH1LightDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH1LightUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH1LightUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH1LightUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH1LightLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH1LightLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 300;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH1RegularDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH1RegularUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH1RegularUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH1RegularUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH1RegularLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH1RegularLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 400;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH1MediumDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH1MediumUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH1MediumUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH1MediumUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH1MediumLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH1MediumLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

%MobileH1BoldDefault {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: none;
    text-transform: none;
}

%MobileH1BoldUnderline {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: underline;
    text-transform: none;
}

%MobileH1BoldUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
}

%MobileH1BoldUnderlineUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-transform: uppercase;
}

%MobileH1BoldLineThrough {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    text-decoration: line-through;
    text-transform: none;
}

%MobileH1BoldLineThroughUppercase {
    font-size: 2.062rem;
    font-family: "Albula Narrow Pro";
    font-weight: 700;
    font-style: normal;
    line-height: 118%;
    letter-spacing: 0.5px;
    text-decoration: line-through;
    text-transform: uppercase;
}

@include media-breakpoint-up(md) {
    %DesktopXsLightDefault {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXsLightUnderline {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXsLightUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXsLightUnderlineUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXsLightLineThrough {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXsLightLineThroughUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXsRegularDefault {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXsRegularUnderline {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXsRegularUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXsRegularUnderlineUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXsRegularLineThrough {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXsRegularLineThroughUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXsMediumDefault {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXsMediumUnderline {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXsMediumUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXsMediumUnderlineUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXsMediumLineThrough {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXsMediumLineThroughUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXsBoldDefault {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXsBoldUnderline {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXsBoldUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXsBoldUnderlineUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXsBoldLineThrough {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXsBoldLineThroughUppercase {
        font-size: 0.875rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopSmLightDefault {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopSmLightUnderline {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopSmLightUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopSmLightUnderlineUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopSmLightLineThrough {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopSmLightLineThroughUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopSmRegularDefault {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopSmRegularUnderline {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopSmRegularUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopSmRegularUnderlineUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopSmRegularLineThrough {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopSmRegularLineThroughUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopSmMediumDefault {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopSmMediumUnderline {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopSmMediumUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopSmMediumUnderlineUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopSmMediumLineThrough {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopSmMediumLineThroughUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopSmBoldDefault {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopSmBoldUnderline {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopSmBoldUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopSmBoldUnderlineUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopSmBoldLineThrough {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopSmBoldLineThroughUppercase {
        font-size: 1rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopMdLightDefault {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopMdLightUnderline {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopMdLightUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopMdLightUnderlineUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopMdLightLineThrough {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopMdLightLineThroughUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopMdRegularDefault {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopMdRegularUnderline {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopMdRegularUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopMdRegularUnderlineUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopMdRegularLineThrough {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopMdRegularLineThroughUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopMdMediumDefault {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopMdMediumUnderline {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopMdMediumUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopMdMediumUnderlineUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopMdMediumLineThrough {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopMdMediumLineThroughUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopMdBoldDefault {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopMdBoldUnderline {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopMdBoldUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopMdBoldUnderlineUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopMdBoldLineThrough {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopMdBoldLineThroughUppercase {
        font-size: 1.062rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 170%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopLgLightDefault {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopLgLightUnderline {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopLgLightUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopLgLightUnderlineUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopLgLightLineThrough {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopLgLightLineThroughUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopLgRegularDefault {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopLgRegularUnderline {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopLgRegularUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopLgRegularUnderlineUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopLgRegularLineThrough {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopLgRegularLineThroughUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopLgMediumDefault {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopLgMediumUnderline {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopLgMediumUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopLgMediumUnderlineUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopLgMediumLineThrough {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopLgMediumLineThroughUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopLgBoldDefault {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopLgBoldUnderline {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopLgBoldUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopLgBoldUnderlineUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopLgBoldLineThrough {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopLgBoldLineThroughUppercase {
        font-size: 1.25rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXlLightDefault {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXlLightUnderline {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXlLightUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXlLightUnderlineUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXlLightLineThrough {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXlLightLineThroughUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXlRegularDefault {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXlRegularUnderline {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXlRegularUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXlRegularUnderlineUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXlRegularLineThrough {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXlRegularLineThroughUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXlMediumDefault {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXlMediumUnderline {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXlMediumUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXlMediumUnderlineUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXlMediumLineThrough {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXlMediumLineThroughUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXlBoldDefault {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXlBoldUnderline {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXlBoldUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXlBoldUnderlineUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXlBoldLineThrough {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXlBoldLineThroughUppercase {
        font-size: 3.5rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXxlLightDefault {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXxlLightUnderline {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXxlLightUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXxlLightUnderlineUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXxlLightLineThrough {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXxlLightLineThroughUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXxlRegularDefault {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXxlRegularUnderline {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXxlRegularUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXxlRegularUnderlineUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXxlRegularLineThrough {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXxlRegularLineThroughUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXxlMediumDefault {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXxlMediumUnderline {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXxlMediumUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXxlMediumUnderlineUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXxlMediumLineThrough {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXxlMediumLineThroughUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopXxlBoldDefault {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopXxlBoldUnderline {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopXxlBoldUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopXxlBoldUnderlineUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopXxlBoldLineThrough {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopXxlBoldLineThroughUppercase {
        font-size: 4rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH6LightDefault {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH6LightUnderline {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH6LightUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH6LightUnderlineUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH6LightLineThrough {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH6LightLineThroughUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH6RegularDefault {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH6RegularUnderline {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH6RegularUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH6RegularUnderlineUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH6RegularLineThrough {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH6RegularLineThroughUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH6MediumDefault {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH6MediumUnderline {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH6MediumUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH6MediumUnderlineUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH6MediumLineThrough {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH6MediumLineThroughUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH6BoldDefault {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH6BoldUnderline {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH6BoldUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH6BoldUnderlineUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH6BoldLineThrough {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH6BoldLineThroughUppercase {
        font-size: 1.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 140%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH5LightDefault {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH5LightUnderline {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH5LightUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH5LightUnderlineUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH5LightLineThrough {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH5LightLineThroughUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH5RegularDefault {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH5RegularUnderline {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH5RegularUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH5RegularUnderlineUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH5RegularLineThrough {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH5RegularLineThroughUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH5MediumDefault {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH5MediumUnderline {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH5MediumUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH5MediumUnderlineUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH5MediumLineThrough {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH5MediumLineThroughUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH5BoldDefault {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH5BoldUnderline {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH5BoldUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH5BoldUnderlineUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH5BoldLineThrough {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH5BoldLineThroughUppercase {
        font-size: 1.375rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH4LightDefault {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH4LightUnderline {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH4LightUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH4LightUnderlineUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH4LightLineThrough {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH4LightLineThroughUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH4RegularDefault {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH4RegularUnderline {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH4RegularUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH4RegularUnderlineUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH4RegularLineThrough {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH4RegularLineThroughUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH4MediumDefault {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH4MediumUnderline {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH4MediumUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH4MediumUnderlineUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH4MediumLineThrough {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH4MediumLineThroughUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH4BoldDefault {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH4BoldUnderline {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH4BoldUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH4BoldUnderlineUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH4BoldLineThrough {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH4BoldLineThroughUppercase {
        font-size: 1.625rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 130%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH3LightDefault {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH3LightUnderline {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH3LightUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH3LightUnderlineUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH3LightLineThrough {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH3LightLineThroughUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH3RegularDefault {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH3RegularUnderline {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH3RegularUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH3RegularUnderlineUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH3RegularLineThrough {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH3RegularLineThroughUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH3MediumDefault {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH3MediumUnderline {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH3MediumUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH3MediumUnderlineUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH3MediumLineThrough {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH3MediumLineThroughUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH3BoldDefault {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH3BoldUnderline {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH3BoldUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH3BoldUnderlineUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH3BoldLineThrough {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH3BoldLineThroughUppercase {
        font-size: 2.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 134%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH2LightDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH2LightUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH2LightUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH2LightUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH2LightLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH2LightLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH2RegularDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH2RegularUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH2RegularUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH2RegularUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH2RegularLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH2RegularLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH2MediumDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH2MediumUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH2MediumUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH2MediumUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH2MediumLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH2MediumLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH2BoldDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH2BoldUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH2BoldUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH2BoldUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH2BoldLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH2BoldLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH1LightDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH1LightUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH1LightUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH1LightUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH1LightLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH1LightLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 300;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH1RegularDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH1RegularUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH1RegularUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH1RegularUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH1RegularLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH1RegularLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 400;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH1MediumDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH1MediumUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH1MediumUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH1MediumUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH1MediumLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH1MediumLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
    %DesktopH1BoldDefault {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: none;
        text-transform: none;
    }
    %DesktopH1BoldUnderline {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: underline;
        text-transform: none;
    }
    %DesktopH1BoldUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
    }
    %DesktopH1BoldUnderlineUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
    }
    %DesktopH1BoldLineThrough {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        text-decoration: line-through;
        text-transform: none;
    }
    %DesktopH1BoldLineThroughUppercase {
        font-size: 3.125rem;
        font-family: "Albula Narrow Pro";
        font-weight: 700;
        font-style: normal;
        line-height: 126%;
        letter-spacing: 0.5px;
        text-decoration: line-through;
        text-transform: uppercase;
    }
}

%ShadowSm {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.200);
}

%ShadowMd {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.200);
}

%ShadowLg {
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.250);
}

%BlurSm {
    backdrop-filter: blur(8px);
}

%BlurMd {
    backdrop-filter: blur(16px);
}

%BlurLg {
    backdrop-filter: blur(36px);
}