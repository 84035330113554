@import "@sx-core/src/scss/element/directions.scss";

.directions {
    color: $color-grey-1300;

    .directions__map {
        height: auto;
        aspect-ratio: 5 / 4;
    }

    &__content {
        padding-left: pxToRem(52);

        .content {
            padding-left: pxToRem(28);
        }
    }

    h5 {
        margin-bottom: pxToRem(12);
        font-size: pxToRem(20);
        color: $color-grey-1300;

        &:not(:first-child) {
            margin-top: pxToRem(48);
        }
    }

    a {
        color: $color-grey-1300;
    }

    p, address {
        margin-bottom: pxToRem(12);
        @include getFigmaStyle('Desktop/LG/Medium/Default');
    }

    table {
        border: none;

        &:not(:last-child) {
            margin-bottom: pxToRem(64);
        }

        th, td {
            padding: initial;
        }

        tr:not(:last-child) {
            border-bottom: none;
        }

        td:not(:last-child) {
            border-right: none;
        }

        tr td:first-child {
            width: 100px;
            @include getFigmaStyle('Desktop/MD/Medium/Default');
        }
    }

    .gmaps-ds-link {
        text-decoration: underline;
    }

    &__hinweis {
        margin-top: pxToRem(64);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/LG/Medium/Default');
    }
}

.directions__map {
    .gw-i-html {
        color: $color-black !important;
    }
}

@include media-breakpoint-down(lg) {
    .directions {
        &__content {
            padding-left: 0;
            margin-top: pxToRem(48);
        }

        .directions__map {
            aspect-ratio: 3 / 4;
        }

        table:not(:last-child) {
            margin-bottom: pxToRem(48);
        }
    }
}

@include media-breakpoint-down(sm) {
    .directions {
        table, td {
            display: block;
        }

        tbody {
            display: flex;
            flex-direction: column;
            row-gap: pxToRem(12);
        }

        tr {
            display: flex;
            flex-direction: column;
            width: 100% !important;
        }
    }
}