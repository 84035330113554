@import "@sx-core/src/scss/element/accordion.scss";

.accordion-item {
    border-color: $color-primary-300;

    &__header {
        padding: pxToRem(36) pxToRem(48) pxToRem(36) 0;

        &::after {
            top: calc(50% - 18px);
            width: pxToRem(36);
            height: pxToRem(36);
            background-color: $color-secondary-800;
            background-image: inline-svg('plus', $color-primary-800);
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
            border-radius: 50%;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                &::after {
                    background-color: $color-primary-800;
                    background-image: inline-svg('plus', $color-secondary-800);
                }
            }
        }

        h3 {
            @include getFigmaStyle('Desktop/LG/Medium/Default');
            margin-bottom: initial;
        }
    }

    &__content {
        padding-bottom: pxToRem(36);
    }

    &__image .image {
        @include getFigmaStyle('Shadow/MD');
    }
}

.accordion__item.uk-open .accordion-item__header::after {
    background-image: inline-svg('minus', $color-black);
    transform: none;
}

.accordion__item.uk-open .accordion-item__header {
    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            &::after {
                background-color: $color-primary-800;
                background-image: inline-svg('minus', $color-secondary-800);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .accordion {
        &-item__content {
            padding-right: 0;
        }
    }
}