@import "@sx-core/src/scss/element/nav.scss";

.burger {
    position: relative;
    top: initial;
    right: initial;
    margin-left: auto;
    z-index: 99999;

    .hamburger {
        width: 48px;
        height: 48px;

        &.is-active {
            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                background-color: $color-white;
            }
        }
    }

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger-box {
        width: 16px;
        height: 16px;
    }

    .hamburger-inner {
        width: 16px;
        height: 2px;
        background-color: $color-primary-800;

        &::before, &::after {
            width: 16px;
            height: 2px;
            background-color: $color-primary-800;
        }

        &::before {
            top: -7px;
        }

        &::after {
            bottom: -7px;
        }
    }
}

.plus {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background-image: inline-svg('plus-nav', $color-white);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 24px;
    background-size: 24px;
}

.header__logo-nav {
    position: absolute;
    top: 28px;
    display: none;
    max-height: 35px;

    img {
        height: 36px;
    }
}

.menu__cta.button {
    display: none;
    margin-right: auto;
}

.header__navigation, .header__services {
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(50% - 90px);

    a {
        white-space: nowrap;
    }

    ul {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        //width: 100%;
        width: auto;
        margin: initial;
    }

    ul > li.level-0 {
        margin: 0 pxToRem(18);
        padding: initial;
        transition: all .3s ease;

        &::after {
            content: '';
            position: absolute;
            bottom: 4px;
            //left: 50%;
            //transform: translateX(-50%);
            display: inline-block;
            width: 0;
            height: 1px;
            background-color: $color-primary-800;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(xl) {
            &:hover, &:focus, &.current.active {
                transform: translateY(-2px);

                &::after {
                    width: 100%;
                }
            }
        }
    }

    ul > li:hover ul {
        pointer-events: auto;
    }

    ul > li a {
        position: relative;
        display: flex;
        align-items: center;
        padding: pxToRem(6) 0;
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        transition: all .3s ease;
    }

    ul ul {
        top: 62px;
        left: 0;
    }

    li.level-0 > ul {
        padding: pxToRem(28);
        background-color: $color-primary-800;
        border-top: 6px solid $color-secondary-800;
    }

    li.level-1:not(.has-children) {
        display: flex;
        align-items: center;
        padding-right: pxToRem(28);
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: pxToRem(4);
            border-bottom: 1px solid $color-primary-600;
        }
    }

    ul > li.level-1 > a {
        width: 100%;
        padding: pxToRem(12) 0 pxToRem(20);
        color: $color-primary-300;

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                color: $color-white;
                transform: translateX(16px);
            }
        }
    }
}

.header__navigation .timetable__outer {
    display: none;
    column-gap: pxToRem(24);
}

.header__services {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(50% - 90px);
    margin-left: auto;

    ul {
        justify-content: flex-end;
    }
}

.header__cta {
    margin-left: pxToRem(18);
    white-space: nowrap;
}

.header__contact {
    display: none;
    color: $color-primary-1200;
    @include getFigmaStyle('Mobile / SM / Regular / Default');

    a {
        color: $color-primary-1200;
    }

    &.contact .contact__email span {
        text-decoration: none;
    }

    span {
        display: inline-block;
        //margin-bottom: pxToRem(8);
        @include getFigmaStyle('Mobile / MD / Bold / Default');
    }
}

@include media-breakpoint-down(xl) {
    .plus {
        display: block;
    }

    .menu__cta.button {
        display: block;
    }

    .js-nav-open {
        overflow: hidden;
    }

    .burger {
        display: inline-block;
        margin-left: initial;
    }

    .header__contact {
        display: block;
    }

    .header__services nav {
        display: none;
    }

    .header__logo-nav {
        display: block;
    }

    .header__navigation {
        position: fixed;
        z-index: 550;
        top: 0;
        left: -100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: pxToRem(48);
        width: 100%;
        min-height: 100vh;
        padding: pxToRem(100) pxToRem(20);
        background-color: $color-primary-1100;
        overflow: auto;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        transition: all .3s ease;

        ul {
            flex-direction: column;
            align-items: initial;
            flex: initial;
            height: auto;
            width: 100%;
        }

        ul ul {
            position: relative;
            top: 0;
            display: none;
        }

        ul > li.level-0 {
            display: flex;
            flex-direction: column;
            height: auto;
            padding: initial;
            margin: pxToRem(32) 0 0;

            &::after {
                content: none;
            }

            & > a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                padding: pxToRem(16) 0;
                color: $color-white;
                font-size: pxToRem(20);
                font-weight: 700;
                line-height: 1.3;
                border-bottom: 2px solid $color-secondary-800;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-primary-800;
                    opacity: 1;
                    transform: none;
                }
            }
        }

        li.level-0 > ul {
            padding: initial;
            background-color: initial;
            border-top: initial;
        }

        ul > li a {
            padding: pxToRem(28) 0 pxToRem(8);
            color: $color-white;
        }

        ul ul > li:first-child > a {
            margin-top: pxToRem(8);
        }

        ul ul > li > a {
            padding: pxToRem(16) 0 pxToRem(8) !important;
            color: $color-white !important;

            &::before {
                content: '';
                display: block;
                width: 28px;
                height: 28px;
                margin-right: pxToRem(16);
                background-color: $color-secondary-800;
                background-image: inline-svg('arrow-s-right', $color-primary-1400);
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                border-radius: 50%;
            }
        }

        ul > li.level-1 {

            &:not(.has-children):not(:last-child) {
                border-bottom: initial;
            }
        }

        .timetable__outer {
            display: flex;
        }
    }

    .header__language {
        ul {
            flex-direction: row;
            align-items: center;

            & > li .language__item--current::before {
                background-color: $color-primary-800;
            }

            & > li:not(:last-child)::after {
                height: 27px;
            }
        }
    }

    .header__additions {
        flex-direction: column;
        row-gap: pxToRem(20);
        width: 100%;
    }

    .js-nav-open .header__navigation {
        left: 0;
    }
}

@include media-breakpoint-down(lg) {
    .header__services .header__cta {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .js-nav-scrolled {
        .burger {
            margin-left: initial;
        }

        .header__booking-btn {
            margin-left: auto;
        }
    }
}

@include media-breakpoint-down(sm) {
    .menu__cta {
        width: 100%;
    }
}