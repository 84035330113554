//@use "sass:math" as math;
/**
 * Import media breakpoints and other shraed vars (js/css)
 */
@import 'shared-vars';
@import 'vars-figma-colors';

/**
 * Use autogenerated media in media mixins
 */
$media-steps: $sx-breakpoints;

$grid: (
        steps: (1, 12),
        gutters: (),
        breakpoint-gutters: (
                'xs': 10px,
                'sm': 16px,
                'md': 24px,
                'lg': 24px,
                'xl': 24px,
                'xxl': 24px,
        ),
        container-paddings: (
                'xs': 36px,
                'sm': 36px,
                'md': 48px,
                'lg': 48px,
                'xl': 48px,
                'xxl': 48px,
        ),
        container-paddings-fluid: (
                'xs': 36px,
                'sm': 36px,
                'md': 48px,
                'lg': 48px,
                'xl': 48px,
                'xxl': 48px,
        )
);

// bulid any combination of the numbers. Please reduce the numbers, if possible.
$image-ratio-steps: (
        1, 2, 3, 4, 5, 7, 9, 16, 18
);

$sx-path-images: '/dist/img/';


$header-height: 80px;

$border-radius: 4px;

/**
 * Spacing vars
 */

/* TBD, ist das immer ein vielfaches von 4? Dann vielleicht lieber sowas wie getSpacing(32,16,('margin-bottom'))*/

$spacing-xxs: pxToRem(8);
$spacing-xs: pxToRem(12);
$spacing-s: pxToRem(14);
$spacing-m: pxToRem(28);
$spacing-l: pxToRem(18);
$spacing-xl: pxToRem(20);
$spacing-xxl: pxToRem(24);

$spacing-desktop-xxs: pxToRem(8);
$spacing-desktop-xs: pxToRem(12);
$spacing-desktop-s: pxToRem(14);
$spacing-desktop-m: pxToRem(28);
$spacing-desktop-l: pxToRem(18);
$spacing-desktop-xl: pxToRem(20);
$spacing-desktop-xxl: pxToRem(24);

$spacing-mobile-xxs: pxToRem(9);
$spacing-mobile-xs: pxToRem(12);
$spacing-mobile-s: pxToRem(14);
$spacing-mobile-m: pxToRem(28);
$spacing-mobile-l: pxToRem(18);
$spacing-mobile-xl: pxToRem(20);
$spacing-mobile-xxl: pxToRem(24);


//$modulspacing-desktop-xxs: pxToRem(9);
//$modulspacing-desktop-xs: pxToRem(12);
$modulspacing-desktop-s: pxToRem(96);
$modulspacing-desktop-m: pxToRem(112);
$modulspacing-desktop-l: pxToRem(144);
//$modulspacing-desktop-xl: pxToRem(20);
//$modulspacing-desktop-xxl: pxToRem(24);

//$modulspacing-mobile-xxs: pxToRem(9);
//$modulspacing-mobile-xs: pxToRem(12);
$modulspacing-mobile-s: pxToRem(28);
$modulspacing-mobile-m: pxToRem(72);
$modulspacing-mobile-l: pxToRem(84);
//$modulspacing-mobile-xl: pxToRem(20);
//$modulspacing-mobile-xxl: pxToRem(24);

/**
 * Color vars
 */

$color-primary: $color-primary-800;
$color-primary-hover: scale_color($color-primary, $lightness: 30%) !default; /*Var for convenience*/

$color-secondary: $color-secondary-800;
$color-secondary-hover: scale_color($color-secondary, $lightness: 30%) !default; /*Var for convenience*/

$color-tertiary: #3FC1C9;
$color-tertiary-hover: scale_color($color-tertiary, $lightness: 30%) !default; /*Var for convenience*/

$color-success: $color-success-800;
$color-warning: $color-warning-800;
$color-danger: $color-error-800;
$color-info: #37C4EF;

//the colors must have so much contrast that they work together as a combination of background and font color
$color-neutral-light: $color-neutral-100;
$color-neutral-dark: $color-neutral-1400;

$color-white: $color-white;
$color-black: $color-black;

$color-background-primary: lighten($color-primary, 30%) !default;
$color-background-secondary: lighten($color-secondary, 30%) !default;
$color-background-tertiary: lighten($color-tertiary, 30%) !default;

$color-background: $color-background-primary; /*Var for convenience*/

/*Vars for convenience*/

$light-percent: 5% !default;
$lighter-percent: 10% !default;
$dark-percent: 5% !default;
$darker-percent: 10% !default;

$color-primary-light: lighten($color-primary, $light-percent) !default;
$color-primary-lighter: lighten($color-primary, $lighter-percent) !default;

$color-primary-dark: darken($color-primary, $dark-percent) !default;
$color-primary-darker: darken($color-primary, $darker-percent) !default;

$color-secondary-light: lighten($color-secondary, $light-percent) !default;
$color-secondary-lighter: lighten($color-secondary, $lighter-percent) !default;

$color-secondary-dark: darken($color-secondary, $dark-percent) !default;
$color-secondary-darker: darken($color-secondary, $darker-percent) !default;

$color-tertiary-light: lighten($color-tertiary, $light-percent) !default;
$color-tertiary-lighter: lighten($color-tertiary, $lighter-percent) !default;

$color-tertiary-dark: darken($color-tertiary, $dark-percent) !default;
$color-tertiary-darker: darken($color-tertiary, $darker-percent) !default;

$color-success-light: lighten($color-success, $light-percent) !default;
$color-success-lighter: lighten($color-success, $lighter-percent) !default;

$color-success-dark: darken($color-success, $dark-percent) !default;
$color-success-darker: darken($color-success, $darker-percent) !default;

$color-warning-light: lighten($color-warning, $light-percent) !default;
$color-warning-lighter: lighten($color-warning, $lighter-percent) !default;

$color-warning-dark: darken($color-warning, $dark-percent) !default;
$color-warning-darker: darken($color-warning, $darker-percent) !default;

$color-danger-light: lighten($color-danger, $light-percent) !default;
$color-danger-lighter: lighten($color-danger, $lighter-percent) !default;

$color-danger-dark: darken($color-danger, $dark-percent) !default;
$color-danger-darker: darken($color-danger, $darker-percent) !default;

$color-info-light: lighten($color-info, $light-percent) !default;
$color-info-lighter: lighten($color-info, $lighter-percent) !default;

$color-info-dark: darken($color-info, $dark-percent) !default;
$color-info-darker: darken($color-info, $darker-percent) !default;


/**
 * Font vars
 */

$sx-path-fonts: '/dist/font/';

$base-font-size: 16px;


//font-size TBD, weil es ja nun aus figma kommt
$font-weight-light: 300;
$font-weight-regular: 500; //default
$font-weight-medium: 600;
$font-weight-semibold: 700;
$font-weight-bold: 800;

$font-size-xxs: pxToRem(9);
$font-size-xs: pxToRem(12);
$font-size-s: pxToRem(14);
$font-size-m: pxToRem($base-font-size);
$font-size-l: pxToRem(18);
$font-size-xl: pxToRem(20);
$font-size-xxl: pxToRem(24);

$font-size-mobile-xxs: $font-size-xxs;
$font-size-mobile-xs: $font-size-xs;
$font-size-mobile-s: $font-size-s;
$font-size-mobile-m: $font-size-m;
$font-size-mobile-l: $font-size-l;
$font-size-mobile-xl: $font-size-xl;
$font-size-mobile-xxl: $font-size-xxl;

$font-family-primary: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-secondary: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-tertiary: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-color-primary: $color-black;
$font-color-secondary: $color-black;
$font-color-tertiary: $color-black;

$font-color: $color-black; /*Var for convenience*/


/**
 * Border radius vars
 */

$border-radius: 0px !default;
$border-radius-button: 5px;

/* If border-radius-button is not defined somewhere else, use border-radius as default */
$border-radius-button: $border-radius !default;



