@import "@sx-core/src/scss/element/intermediate.scss";

.intermediate {
    height: auto;
    overflow: initial !important;
}

.intermediate__outer {
    position: relative;
    top: 0;
}

.intermediate__item {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: hidden;

    &.intermediate__item--single {
        height: auto;
    }
}

.intermediate__item:not(.intermediate__item--single) .image img, .intermediate__item:not(.intermediate__item--single) .video img {
    height: 100vh;
}

@supports (height: 100svh) {
    .intermediate__item {
        height: 100svh;
    }

    .intermediate__item:not(.intermediate__item--single) .image img, .intermediate__item:not(.intermediate__item--single) .video img {
        height: 100svh;
    }
}