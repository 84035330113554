
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: 400;
    color: $font-color;
}

h1, .h1, h2, .h2 {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H1/Medium/Default');
    margin-bottom: pxToRem(16);
}

h3, .h3 {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H3/Medium/Default');
    margin-bottom: pxToRem(16);
}

h4, .h4 {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H4/Medium/Default');
    margin-bottom: pxToRem(16);
}

h5, .h5 {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H5/Medium/Default');
    margin-bottom: pxToRem(16);
}

h6, .h6 {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H6/Medium/Default');
    margin-bottom: pxToRem(16);
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2 {
        margin-bottom: pxToRem(20);
    }

    h3, .h3 {
        margin-bottom: pxToRem(20);
    }

    h4, .h4 {
        margin-bottom: pxToRem(20);
    }

    h5, .h5 {
        margin-bottom: pxToRem(20);
    }

    h6, .h6 {
        margin-bottom: pxToRem(20);
    }
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

.visuallyhidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

address {
    font-style: initial;
}

a {
    position: relative;
    text-decoration: none;
}

a:not(.teaser-item) {
    z-index: 10;
}

ins {
    text-decoration: none;
}


h1, .h1 {
    font-size: pxToRem(40)
}

.modulspacing--m {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;

    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

@include media-breakpoint-up(lg) {
    #mousefollower {
        position: fixed;
        width: 31px;
        height: 24px;
        background-image: url("/assets/img/logo-hamburger-wackelzaehne-bildmarke.svg");
        background-position: center;
        background-size: 31px 24px;
        background-repeat: no-repeat;
        pointer-events: none;
        //transform: translate(-50%, -50%);
        z-index: 0;
    }
}

@include media-breakpoint-down(lg) {
    body .content ul {
        margin-top: pxToRem(28);
        margin-bottom: pxToRem(28);

        li {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }

    .editor {
        h1, .h1, h2, .h2 {
            margin-bottom: pxToRem(20);
        }
    }
}