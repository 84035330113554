.timetable__outer {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(24);
}

.timetable {
    padding-left: pxToRem(28);
    border-left: 3px solid $color-secondary-800;

    &__headline {
        margin-bottom: pxToRem(12);
        color: $color-white;
        @include getFigmaStyle('Desktop/LG/Bold/Default');
    }

    table {
        color: $color-primary-300;

        td:first-child {
            display: flex;
            min-width: pxToRem(120);
            @include getFigmaStyle('Desktop/MD/Medium/Default');
        }

        td:not(:last-child) {
            padding-right: pxToRem(28);
        }
    }
}