@import "@sx-core/src/scss/base/slider.base.scss";
@import "@sx-core/src/scss/element/slider.scss";

/* Liebe Mitarbeitende mit A am anfang, wir haben nette Beispiele für euch*/
//@import "@sx-core/src/scss/element/slider-center.scss";
//@import "@sx-core/src/scss/element/slider-open-left.scss";
//@import "@sx-core/src/scss/element/slider-one-big-centered.scss";

.splide__pagination, .splide__arrows {
    display: none;
}