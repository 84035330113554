.iconleiste {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: pxToRem(28);
    padding-left: pxToRem(128);
    padding-right: pxToRem(128);
    margin-top: pxToRem(64);
    margin-bottom: pxToRem(96);
    background-color: $color-primary-1100;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: pxToRem(12);
        max-width: 260px;
        text-align: center;
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background: $color-primary-800;
        background-position: center;
        background-size: 48px;
        background-repeat: no-repeat;
        border-radius: 50%;
    }

    &__txt {
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }
}

.icon {
    &--badge {
        background-image: inline-svg('badge', $color-white);
    }

    &--time {
        background-image: inline-svg('time', $color-white);
    }

    &--calm {
        background-image: inline-svg('calm', $color-white);
    }

    &--hands {
        background-image: inline-svg('hands', $color-white);
    }
}


@include media-breakpoint-down(md) {
    .iconleiste {
        justify-content: center;
        padding-left: 28px;
        padding-right: 28px;
        margin-top: pxToRem(48);
        margin-bottom: pxToRem(72);
    }
}