@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        // margin-bottom: pxToRem(8);
        // line-height: 1.2;
    }

    h5, .h5, h6, .h6 {
        //margin-bottom: pxToRem(16);
        //line-height: 1.3;
    }

    h1, .h1, h2, .h2 {
        margin-top: pxToRem(48);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H1/Medium/Default');
    }

    h3, .h3 {
        margin-top: pxToRem(48);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H3/Medium/Default');
    }

    h4, .h4 {
        margin-top: pxToRem(48);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H4/Medium/Default');
    }

    h5, .h5 {
        margin-top: pxToRem(48);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H5/Medium/Default');
    }

    h6, .h6 {
        margin-top: pxToRem(48);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H6/Medium/Default');
    }

    p {
        color: $color-primary-1300;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        margin-bottom: pxToRem(12);
    }

    p, ul, ol, hr, table {

    }

    hr {
        // border-top: 1px solid $color-dark;
    }

    a {
        //text-decoration: underline;
    }

    .content a {
        color: $color-grey-1300;
        @include getFigmaStyle('Desktop/MD/Bold/Underline');
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(4);
    }

    ul {
        //padding-left: pxToRem(19);
        display: flex;
        flex-wrap: wrap;
        margin-top: pxToRem(48);
        margin-bottom: pxToRem(48);
        list-style-type: none;
        column-gap: pxToRem(8);
        row-gap: pxToRem(20);
    }

    li.list--singlecolumn {
        width: 100%;
    }

    ul li {
        width: calc(50% - 4px);
        position: relative;
        padding-left: pxToRem(36);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
        hyphens: auto;
        break-inside: avoid-column;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            display: inline-block;
            width: 24px;
            height: 20px;
            margin-right: pxToRem(12);
            background-image: inline-svg('tooth', $color-primary-800, transparent);
            background-position: center;
            background-size: 24px 20px;
            background-repeat: no-repeat;
        }
    }

    ul li::marker,
    ol li::marker {
        font-size: pxToRem(16);
        color: $color-primary;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        text-align: left;
        border: 1px solid $color-grey-200;
        width: 100%;
    }

    table th,
    table td,
    table thead th,
    table tbody td {
        text-align: left;
        background: transparent;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-black;
    }

    th, td {
        padding: 8px 12px;
    }

    th:not(:last-child),
    td:not(:last-child) {
        border-right: 1px solid $color-grey-100;
    }

    em, i {
        font-style: italic;
    }


}