@import "@sx-core/src/scss/element/keyvisual.scss";

.keyvisual {
    margin-top: pxToRem(152);
    overflow: initial;

    @include media-breakpoint-up(lg) {
        z-index: -1;
    }

    .keyvisual {
        padding: initial;
        margin-top: initial;
        overflow: hidden;
    }

    .splide__arrows, .video__unmute {
        display: none;
    }
}

.keyvisual__empty {
    height: pxToRem(152);
}

.keyvisual {
    height: calc(100vh - 152px);

    .keyvisual {
        height: 100%;
    }

    ul {
        height: 100%;
    }
}

.keyvisual__cta {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    display: none;
    justify-content: center;
    width: 100%;
    padding: 0 pxToRem(20);
}

@include media-breakpoint-down(xl) {
    .keyvisual {
        margin-top: pxToRem(96);
        height: calc(100vh - 96px);
    }

    .keyvisual__empty {
        height: pxToRem(96);
    }
}

@include media-breakpoint-down(lg) {
    .keyvisual__cta {
        display: flex;
    }
}

@include media-breakpoint-down(md) {
    .keyvisual {
        height: auto;
    }
}

@include media-breakpoint-down(sm) {
    .keyvisual__cta .button {
        width: 100%;
    }
}