$color-black: rgba(0,0,0,1);
$color-white: rgba(255,255,255,1);
$color-greenish: rgba(243,247,241,1);
$color-image-default: rgba(0,0,0,0.080);
$color-image-hover: rgba(0,0,0,0.300);
$color-image-verlauf: linear-gradient(to bottom, rgba(0,0,0,0.000) 0%,rgba(0,0,0,0.000) 100%);
$color-black-alpha-100: rgba(0,0,0,0.050);
$color-black-alpha-200: rgba(0,0,0,0.071);
$color-black-alpha-300: rgba(0,0,0,0.143);
$color-black-alpha-400: rgba(0,0,0,0.214);
$color-black-alpha-500: rgba(0,0,0,0.286);
$color-black-alpha-600: rgba(0,0,0,0.357);
$color-black-alpha-700: rgba(0,0,0,0.429);
$color-black-alpha-800: rgba(0,0,0,0.500);
$color-black-alpha-900: rgba(0,0,0,0.571);
$color-black-alpha-1000: rgba(0,0,0,0.643);
$color-black-alpha-1100: rgba(0,0,0,0.714);
$color-black-alpha-1200: rgba(0,0,0,0.786);
$color-black-alpha-1300: rgba(0,0,0,0.857);
$color-black-alpha-1400: rgba(0,0,0,0.929);
$color-white-alpha-100: rgba(255,255,255,0.050);
$color-white-alpha-200: rgba(255,255,255,0.071);
$color-white-alpha-300: rgba(254,254,254,0.143);
$color-white-alpha-400: rgba(255,255,255,0.214);
$color-white-alpha-500: rgba(255,255,255,0.286);
$color-white-alpha-600: rgba(255,255,255,0.357);
$color-white-alpha-700: rgba(254,254,254,0.429);
$color-white-alpha-800: rgba(255,255,255,0.500);
$color-white-alpha-900: rgba(255,255,255,0.571);
$color-white-alpha-1000: rgba(255,255,255,0.643);
$color-white-alpha-1100: rgba(255,255,255,0.714);
$color-white-alpha-1200: rgba(255,255,255,0.786);
$color-white-alpha-1300: rgba(255,255,255,0.857);
$color-white-alpha-1400: rgba(255,255,255,0.929);
$color-success-100: rgba(241,248,245,1);
$color-success-200: rgba(236,246,241,1);
$color-success-300: rgba(215,237,226,1);
$color-success-400: rgba(193,227,210,1);
$color-success-500: rgba(167,217,193,1);
$color-success-600: rgba(137,207,174,1);
$color-success-700: rgba(97,196,153,1);
$color-success-800: rgba(16,185,129,1);
$color-success-900: rgba(14,171,119,1);
$color-success-1000: rgba(13,156,109,1);
$color-success-1100: rgba(12,139,97,1);
$color-success-1200: rgba(10,121,84,1);
$color-success-1300: rgba(8,98,68,1);
$color-success-1400: rgba(6,69,48,1);
$color-warning-100: rgba(254,247,241,1);
$color-warning-200: rgba(253,243,236,1);
$color-warning-300: rgba(252,231,215,1);
$color-warning-400: rgba(250,218,192,1);
$color-warning-500: rgba(249,205,167,1);
$color-warning-600: rgba(247,190,136,1);
$color-warning-700: rgba(246,175,96,1);
$color-warning-800: rgba(245,158,11,1);
$color-warning-900: rgba(226,146,10,1);
$color-warning-1000: rgba(207,133,9,1);
$color-warning-1100: rgba(185,119,8,1);
$color-warning-1200: rgba(160,103,7,1);
$color-warning-1300: rgba(131,84,5,1);
$color-warning-1400: rgba(92,59,4,1);
$color-error-100: rgba(251,235,234,1);
$color-error-200: rgba(249,226,225,1);
$color-error-300: rgba(243,197,194,1);
$color-error-400: rgba(237,168,164,1);
$color-error-500: rgba(230,139,134,1);
$color-error-600: rgba(224,110,104,1);
$color-error-700: rgba(218,81,73,1);
$color-error-800: rgba(212,52,43,1);
$color-error-900: rgba(182,45,37,1);
$color-error-1000: rgba(151,37,31,1);
$color-error-1100: rgba(121,30,25,1);
$color-error-1200: rgba(91,22,18,1);
$color-error-1300: rgba(61,15,12,1);
$color-error-1400: rgba(30,7,6,1);
$color-grey-100: rgba(242,242,243,1);
$color-grey-200: rgba(237,237,237,1);
$color-grey-300: rgba(218,218,219,1);
$color-grey-400: rgba(200,199,202,1);
$color-grey-500: rgba(181,181,184,1);
$color-grey-600: rgba(163,162,166,1);
$color-grey-700: rgba(144,143,148,1);
$color-grey-800: rgba(126,125,130,1);
$color-grey-900: rgba(108,107,112,1);
$color-grey-1000: rgba(90,89,93,1);
$color-grey-1100: rgba(72,72,74,1);
$color-grey-1200: rgba(54,53,56,1);
$color-grey-1300: rgba(36,36,37,1);
$color-grey-1400: rgba(18,18,18,1);
$color-neutral-100: rgba(241,242,243,1);
$color-neutral-200: rgba(235,236,238,1);
$color-neutral-300: rgba(216,218,222,1);
$color-neutral-400: rgba(196,199,205,1);
$color-neutral-500: rgba(176,180,188,1);
$color-neutral-600: rgba(156,161,171,1);
$color-neutral-700: rgba(137,143,155,1);
$color-neutral-800: rgba(117,124,138,1);
$color-neutral-900: rgba(100,106,118,1);
$color-neutral-1000: rgba(84,89,99,1);
$color-neutral-1100: rgba(67,71,79,1);
$color-neutral-1200: rgba(50,53,59,1);
$color-neutral-1300: rgba(33,35,39,1);
$color-neutral-1400: rgba(17,18,20,1);
$color-primary-100: rgba(239,235,243,1);
$color-primary-200: rgba(232,226,239,1);
$color-primary-300: rgba(208,197,222,1);
$color-primary-400: rgba(184,168,205,1);
$color-primary-500: rgba(161,138,189,1);
$color-primary-600: rgba(137,109,172,1);
$color-primary-700: rgba(113,80,155,1);
$color-primary-800: rgba(90,51,139,1);
$color-primary-900: rgba(77,44,119,1);
$color-primary-1000: rgba(64,36,99,1);
$color-primary-1100: rgba(51,29,80,1);
$color-primary-1200: rgba(39,22,59,1);
$color-primary-1300: rgba(26,15,40,1);
$color-primary-1400: rgba(13,7,20,1);
$color-secondary-100: rgba(246,252,233,1);
$color-secondary-200: rgba(243,250,224,1);
$color-secondary-300: rgba(230,246,193,1);
$color-secondary-400: rgba(217,241,162,1);
$color-secondary-500: rgba(205,236,131,1);
$color-secondary-600: rgba(192,231,100,1);
$color-secondary-700: rgba(179,227,69,1);
$color-secondary-800: rgba(167,222,38,1);
$color-secondary-900: rgba(143,190,33,1);
$color-secondary-1000: rgba(119,159,27,1);
$color-secondary-1100: rgba(96,127,22,1);
$color-secondary-1200: rgba(71,95,16,1);
$color-secondary-1300: rgba(48,63,11,1);
$color-secondary-1400: rgba(24,32,5,1);